//==============================================================================
// Invisible Anchor
//
// Anchor tag with unique ID to allow jumping to its position on page.
//==============================================================================
import * as React from 'react';

import { IInvisibleAnchorProps } from './invisible-anchor.props.autogenerated';

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * InvisibleAnchor component
 * @extends {React.PureComponent<IInvisibleAnchorProps<{}>>}
 */
//==============================================================================
class InvisibleAnchor extends React.PureComponent<IInvisibleAnchorProps<{}>> {
    public render(): JSX.Element {
        return (
            <a className='invisible-anchor' id={this.props.config.anchorId} />
        );
    }
}

export default InvisibleAnchor;
